import { css } from '@emotion/react';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import { match } from 'ts-pattern';
import { Container } from '../common/container';

const navigationItems: { [label: string]: string } = {
    story: '/#story',
    roadmap: '/#roadmap',
    team: '/#team',
    partners: '/#partners',
    faq: '/#faq',
    mint: '/mint!',
};

export const Header: React.VFC = () => {
    const breakpoints = useBreakpoint();
    const linkPadding = match(breakpoints)
        .with({ sm: true }, () => 5)
        .with({ md: true }, () => 10)
        .otherwise(() => 40);

    const logoGap = match(breakpoints)
        .with({ sm: true }, () => 10)
        .with({ md: true }, () => 20)
        .otherwise(() => 40);

    const queryResult = useStaticQuery<{
        logoImage: {
            childImageSharp: {
                gatsbyImageData: ImageDataLike;
            };
        };
    }>(graphql`
        query {
            logoImage: file(relativePath: { eq: "images/logo-dark.png" }) {
                childImageSharp {
                    gatsbyImageData(height: 100)
                }
            }
        }
    `);

    const logoImage = getImage(
        queryResult.logoImage.childImageSharp.gatsbyImageData
    );

    return (
        <div
            css={css`
                width: 100%;
                background-color: #171717;

                padding: 25px 0;
            `}
        >
            <Container
                width={1300}
                css={css`
                    display: flex;
                    flex-direction: row;
                    column-gap: ${logoGap}px;
                `}
            >
                <div>
                    <Link to="/">
                        {logoImage && (
                            <GatsbyImage
                                image={logoImage}
                                alt="Adena Vox Logo"
                            />
                        )}
                    </Link>
                </div>
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                    `}
                >
                    {Object.entries(navigationItems).map(([label, path]) => (
                        <button
                            key={label}
                            className={path.endsWith('!') ? 'disabled' : ''}
                            onClick={async () => {
                                if (path.endsWith('!')) return;

                                const pathElements = path.split('#');
                                await navigate(pathElements[0]);

                                if (pathElements[1]) {
                                    const element = document.getElementById(
                                        pathElements[1]
                                    );

                                    if (element) {
                                        setTimeout(() => {
                                            console.log(element);

                                            element.scrollIntoView({
                                                behavior: 'smooth',
                                            });
                                        }, 50);
                                    }
                                }
                            }}
                            css={css`
                                color: #fff;
                                background: none;
                                border: none;
                                cursor: pointer;

                                font-family: pixel;
                                text-decoration: none;
                                font-size: 1.5rem;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                transition: color 0.2s;

                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 0 ${linkPadding}px;

                                :hover {
                                    color: #ffd459;
                                }

                                &.disabled {
                                    color: #999;
                                    cursor: not-allowed;
                                    :hover {
                                        color: #999;
                                    }
                                }
                            `}
                        >
                            {label}
                        </button>
                    ))}
                </div>
            </Container>
        </div>
    );
};
