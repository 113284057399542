import { css } from '@emotion/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import { match } from 'ts-pattern';

export const Container: React.FC<{
    width?: number;
    className?: string;
}> = ({ width, children, ...props }) => {
    const breakpoints = useBreakpoint();

    const calculatedWidth = width
        ? `${width}px`
        : match(breakpoints)
              .with({ sm: true }, () => '100%')
              .with({ md: true }, () => '700px')
              .with({ l: true }, () => '1100px')
              .otherwise(() => '1100px');

    const padding = '0 20px';

    return (
        <div
            css={css`
                max-width: ${calculatedWidth};
                padding: ${padding};
                margin: 0 auto;
            `}
            {...props}
        >
            {children}
        </div>
    );
};
