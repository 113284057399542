import React from 'react';
import { css, Global } from '@emotion/react';

import pixelFont from '../assets/fonts/upheavtt.ttf';
import pixelHangulFont from '../assets/fonts/neodgm.ttf';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { match } from 'ts-pattern';

export const DefaultStyle = () => {
    const breakpoints = useBreakpoint();

    const fontScale = match(breakpoints)
        .with({ xs: true }, () => 0.4)
        .with({ sm: true }, () => 0.6)
        .with({ md: true }, () => 0.8)
        .otherwise(() => 1);

    return (
        <Global
            styles={css`
                @font-face {
                    font-family: pixel;
                    src: url('${pixelFont}');
                }

                @font-face {
                    font-family: pixel-hangul;
                    src: url('${pixelHangulFont}');
                }

                :root {
                    /*
                        define styles
                    */
                    font-family: 'Open Sans', sans-serif;
                    color: var(--color-dark-text);
                    overflow-y: scroll;

                    line-height: 1;
                    font-size: ${fontScale}rem;
                }

                body {
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    p {
                        margin: 0;
                    }

                    overflow-x: hidden;
                }
            `}
        />
    );
};
