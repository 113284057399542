import { css } from '@emotion/react';
import React from 'react';

import twitterIconImage from '../../../assets/images/icon-twitter.png';
import redditIconImage from '../../../assets/images/icon-reddit.png';
import instagramIconImage from '../../../assets/images/icon-instagram.png';

const snsButtons = [
    {
        name: 'Twitter',
        image: twitterIconImage as string,
        link: 'https://twitter.com/AdenaVox/',
    },
    {
        name: 'Reddit',
        image: redditIconImage as string,
        link: 'https://discord.gg/9na7HMxY',
    },
    {
        name: 'Instagram',
        image: instagramIconImage as string,
        link: 'https://instagram.com/',
    },
];

export const Footer: React.VFC = () => {
    return (
        <div
            css={css`
                width: 100%;
                padding: 120px 0;
                background-color: #171717;
            `}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    column-gap: 32px;
                    margin-bottom: 32px;
                `}
            >
                {snsButtons.map((e) => (
                    <button
                        key={`footer-sns-${e.name}`}
                        css={css`
                            background: #fff;
                            border: none;
                            width: 80px;
                            height: 80px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 16px;
                            cursor: pointer;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                                0px 0px 20px rgba(0, 0, 0, 0.25);
                        `}
                        onClick={() => {
                            window.open(e.link, '_blank');
                        }}
                    >
                        <img
                            src={e.image}
                            alt={e.name}
                            css={css`
                                width: 40px;
                            `}
                        />
                    </button>
                ))}
            </div>
            <div
                css={css`
                    font-family: pixel;
                    font-size: 1.7rem;
                    color: #fff;
                    text-align: center;
                `}
            >
                2022 BY ADENA VOX
            </div>
        </div>
    );
};
