import 'normalize.css';
import '@fontsource/open-sans';
import React, { FC } from 'react';
import { DefaultStyle } from '../styles/default';
import { Header } from '../components/layout/default/header';
import { Footer } from '../components/layout/default/footer';

export const DefaultLayout: FC = ({ children }) => {
    return (
        <>
            <DefaultStyle />
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    );
};
